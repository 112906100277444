












import { Component, Vue, Prop } from 'vue-property-decorator'
import ObjectiveProgress from '@/components/molecules/ObjectiveProgress.vue'
import { StudentGoal } from '@/models/api/studentGoal'

@Component({
  components: {
    ObjectiveProgress,
  },
})
// 教師側の生徒履歴: 目標達成状況タブでの使用
export default class StudentObjective extends Vue {
  @Prop({ default: [] })
  goals!: StudentGoal[]

  @Prop()
  onClickDetailBtn!: Function

  private currentModalContent: any = null

  private onClickDetail(goal: StudentGoal) {
    this.onClickDetailBtn(goal)
  }
}
